<template>
  <div>
    <a-skeleton :loading="isProfileLoading" active>
      <div v-if="!isProfileLoading">
        <div>
          <div class="card card-top card-top-primary">
            <div class="card-header card-header-flex">
              <div class="d-flex flex-column justify-content-center mr-auto">
                <h5 class="mb-0">
                  <strong v-if="!isEdit">New Vendor</strong>
                  <strong v-else>Edit Vendor - {{ formState?.vid }}</strong>
                </h5>
              </div>
              <!-- Add actions here if needed -->
            </div>
            <div class="card-body">
              <a-form
                :model="formState"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :scroll-to-first-error="true"
                @finish="handleFinish"
                @finishFailed="handleFinishFailed"
              >
                <div class="row">
                  <div class="col-md-8">
                    <a-form-item label="Vendor Type" name="vendorType">
                      <a-radio-group v-model:value="formState.vendorType">
                        <a-radio value="business">Business</a-radio>
                        <a-radio value="agent">Agent</a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <a-form-item label="Full Name" name="fullName">
                      <a-input
                        placeholder="Enter Full Name"
                        v-model:value="formState.fullName"
                        @blur="formState.displayName = formState.fullName"
                        allow-clear
                      />
                    </a-form-item>
                    <a-form-item label="Display Name" name="displayName">
                      <a-auto-complete
                        placeholder="Enter Display Name"
                        v-model:value="formState.displayName"
                        :options="displayNameOptions"
                      />
                    </a-form-item>
                    <a-form-item label="Alias" name="alias">
                      <a-input
                        placeholder="Enter alias in lowercase without spaces"
                        v-model:value="formState.alias"
                        allow-clear
                        @blur="onAliasInputBlur"
                      />
                    </a-form-item>
                    <a-form-item label="Email" name="email">
                      <a-input
                        placeholder="Enter Email Address"
                        v-model:value="formState.email"
                        allow-clear
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item label="Mobile" name="mobile">
                      <a-input
                        placeholder="Enter Phone"
                        type="number"
                        v-model:value="formState.mobile"
                        allow-clear
                      />
                    </a-form-item>
                    <a-form-item label="GSTIN" name="gstin">
                      <a-input
                        placeholder="Enter GSTIN"
                        v-model:value="formState.gstin"
                        allow-clear
                      />
                    </a-form-item>
                    <a-form-item label="Website" name="website">
                      <a-input
                        placeholder="Enter Website URL"
                        v-model:value="formState.website"
                        allow-clear
                      />
                    </a-form-item>
                    <a-form-item label="Slug" name="slug">
                      <a-input
                        placeholder="Enter Tracking Slug"
                        v-model:value="formState.trackingSlug"
                        allow-clear
                      />
                    </a-form-item>
                  </div>
                </div>
                <a-divider />
                <div>
                  <a-tabs :default-active-key="1" class="vb-tabs-bordered mb-2" :animated="false">
                    <!-- Address TAB -->
                    <a-tab-pane key="1">
                      <template #tab>
                        <span class="p-4">
                          Address
                        </span>
                      </template>
                      <div>
                        <InputFormAddress v-model:address="formState.primaryAddress" />
                      </div>
                    </a-tab-pane>

                    <!-- Contact Persons TAB -->
                    <a-tab-pane key="2">
                      <template #tab>
                        <span class="p-4">
                          Contact Persons
                        </span>
                      </template>
                      <div class="card-body">
                        <a-button class="mb-4" @click="isAddContactPersonModalVisible = true">
                          Add Contact Person
                        </a-button>
                        <InputFormContactPersonModal
                          v-model:isAddContactPersonModalVisible="isAddContactPersonModalVisible"
                          @onAddContactPerson="handleAddContactPerson"
                        />
                        <a-table
                          :columns="contactPersonColumns"
                          :data-source="formState.contactPersons"
                          :row-key="(rec, idx) => idx"
                          :bordered="true"
                        >
                          <template #actions="{index}">
                            <div>
                              <a-popconfirm
                                title="Sure to delete?"
                                @confirm="() => formState.contactPersons.splice(index, 1)"
                              >
                                <a>
                                  <i class="fe fe-x-circle" />
                                </a>
                              </a-popconfirm>
                            </div>
                          </template>
                        </a-table>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                  <div>
                    <a-button
                      html-type="submit"
                      :loading="loading"
                      class="btn btn-primary px-5 mr-3"
                    >
                      <span v-if="!isEdit">Save</span>
                      <span v-else>Update</span>
                    </a-button>
                    <a-button class="btn btn-light px-5" @click.prevent="$router.go(-1)">
                      Cancel
                    </a-button>
                  </div>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </a-skeleton>
  </div>
</template>

<script lang="ts">
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onBeforeMount, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import InputFormContactPersonModal from '@/components/InputForms/InputFormContactPersonModal.vue'
import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { VendorTypes } from '@/types/enums/VendorTypes'
import { VendorFormData } from '@/types/appcontracts/VendorFormData'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { VendorsDocument } from '@/types/firebaseCollectionContracts/VendorsDocument'
import { message } from 'ant-design-vue'
import { IDocumentAudit } from '@/types/interfaces/IDocumentAudit'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}

const contactPersonColumns = [
  {
    title: 'Full Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    ellipsis: true,
    key: 'cpEmail',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Notes',
    dataIndex: 'cpNotes',
    key: 'cpNotes',
    ellipsis: true,
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  components: {
    InputFormAddress,
    InputFormContactPersonModal,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const loading = ref(false)
    const isAddContactPersonModalVisible = ref(false)
    const vendorsAliasList = computed<Array<string | null>>(
      () => store.getters['vendors/vendorsAliasList'],
    )
    const isProfileLoading = ref(false)
    const prevAudit = ref<IDocumentAudit | null>(null)
    const isEdit = computed(() => route.params.id !== null && route.params.id !== undefined)
    const fetchedAlias = ref<string | null>(null)
    const formState = reactive<VendorFormData>({
      vid: null,
      vendorType: VendorTypes.Business,
      fullName: null,
      displayName: null,
      alias: null,
      email: null,
      mobile: null,
      gstin: null,
      primaryAddress: {
        country: 'India',
        attention: null,
        city: null,
        state: null,
        address: null,
        pincode: null,
      },
      website: null,
      contactPersons: [],
      photoUrl: null,
      trackingSlug: null,
    })

    const isAliasUnique = async () => {
      return vendorsAliasList.value.includes(formState.alias) &&
        fetchedAlias.value !== formState.alias
        ? Promise.reject('Alias already exists.')
        : Promise.resolve(true)
    }

    const rules = {
      displayName: [
        { required: true, message: 'Please enter vendor display name', trigger: 'blur' },
      ],
      alias: [
        {
          required: true,
          type: 'string',
          min: 2,
          message: 'Please input valid alias(min length 2)',
          trigger: 'blur',
        },
        { validator: isAliasUnique, trigger: 'blur' },
      ],
      email: [{ type: 'email', message: 'Please input a valid email address', trigger: 'blur' }],
      gstin: [{ type: 'string', len: 15, message: 'Please enter valid gstin', trigger: 'blur' }],
    }

    const displayNameOptions = computed(() => {
      let displayNameArr: Array<Object> = []

      if (formState.fullName) {
        displayNameArr.push({ value: formState.fullName })
      }

      return displayNameArr
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<VendorFormData> = {
        action: isEdit.value ? DocumentActions.UPDATE : DocumentActions.CREATE,
        id: isEdit.value ? (route.params.id as string) : null,
        payload: toRaw(formState),
        audit: isEdit.value ? prevAudit.value : null,
      }
      const isSuccess = await store.dispatch(
        `vendors/${actions.VendorsAction.SET_OR_DELETE_VENDOR}`,
        docOperation,
      )
      loading.value = false
      if (isSuccess) {
        router.push({ name: 'vendors' })
      }
    }

    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const handleAddContactPerson = (contactPerson: IContactPerson) => {
      formState?.contactPersons?.push(contactPerson)
    }

    const onAliasInputBlur = () => {
      // TODO: Check for unique alias from store
      formState.alias = formState.alias?.replace(/\s/g, '').toLowerCase() ?? null
    }

    const fetchProfile = async (vid?: string) => {
      if (vid) {
        isProfileLoading.value = true
        const resp: VendorsDocument | null = await store.dispatch(
          `vendors/${actions.VendorsAction.FETCH_VENDOR_PROFILE}`,
          {
            id: vid,
          },
        )
        if (!resp) {
          message.warn('Customer does not exists.')
          router.push({ name: 'vendors' })
        } else {
          let { audit, ...doc } = resp
          Object.assign(formState, doc)
          fetchedAlias.value = doc.alias
          prevAudit.value = audit
        }
        isProfileLoading.value = false
      }
    }

    onBeforeMount(async () => {
      await fetchProfile(route.params.id as string)
    })

    onBeforeRouteUpdate(async (to) => {
      await fetchProfile(to.params.id as string)
    })

    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      loading,
      handleFinish,
      handleFinishFailed,
      isAddContactPersonModalVisible,
      handleAddContactPerson,
      contactPersonColumns,
      displayNameOptions,
      onAliasInputBlur,
      isProfileLoading,
      isEdit,
    }
  },
})
</script>
