
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onBeforeMount, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import InputFormContactPersonModal from '@/components/InputForms/InputFormContactPersonModal.vue'
import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { VendorTypes } from '@/types/enums/VendorTypes'
import { VendorFormData } from '@/types/appcontracts/VendorFormData'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { VendorsDocument } from '@/types/firebaseCollectionContracts/VendorsDocument'
import { message } from 'ant-design-vue'
import { IDocumentAudit } from '@/types/interfaces/IDocumentAudit'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}

const contactPersonColumns = [
  {
    title: 'Full Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    ellipsis: true,
    key: 'cpEmail',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Notes',
    dataIndex: 'cpNotes',
    key: 'cpNotes',
    ellipsis: true,
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  components: {
    InputFormAddress,
    InputFormContactPersonModal,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const loading = ref(false)
    const isAddContactPersonModalVisible = ref(false)
    const vendorsAliasList = computed<Array<string | null>>(
      () => store.getters['vendors/vendorsAliasList'],
    )
    const isProfileLoading = ref(false)
    const prevAudit = ref<IDocumentAudit | null>(null)
    const isEdit = computed(() => route.params.id !== null && route.params.id !== undefined)
    const fetchedAlias = ref<string | null>(null)
    const formState = reactive<VendorFormData>({
      vid: null,
      vendorType: VendorTypes.Business,
      fullName: null,
      displayName: null,
      alias: null,
      email: null,
      mobile: null,
      gstin: null,
      primaryAddress: {
        country: 'India',
        attention: null,
        city: null,
        state: null,
        address: null,
        pincode: null,
      },
      website: null,
      contactPersons: [],
      photoUrl: null,
      trackingSlug: null,
    })

    const isAliasUnique = async () => {
      return vendorsAliasList.value.includes(formState.alias) &&
        fetchedAlias.value !== formState.alias
        ? Promise.reject('Alias already exists.')
        : Promise.resolve(true)
    }

    const rules = {
      displayName: [
        { required: true, message: 'Please enter vendor display name', trigger: 'blur' },
      ],
      alias: [
        {
          required: true,
          type: 'string',
          min: 2,
          message: 'Please input valid alias(min length 2)',
          trigger: 'blur',
        },
        { validator: isAliasUnique, trigger: 'blur' },
      ],
      email: [{ type: 'email', message: 'Please input a valid email address', trigger: 'blur' }],
      gstin: [{ type: 'string', len: 15, message: 'Please enter valid gstin', trigger: 'blur' }],
    }

    const displayNameOptions = computed(() => {
      let displayNameArr: Array<Object> = []

      if (formState.fullName) {
        displayNameArr.push({ value: formState.fullName })
      }

      return displayNameArr
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<VendorFormData> = {
        action: isEdit.value ? DocumentActions.UPDATE : DocumentActions.CREATE,
        id: isEdit.value ? (route.params.id as string) : null,
        payload: toRaw(formState),
        audit: isEdit.value ? prevAudit.value : null,
      }
      const isSuccess = await store.dispatch(
        `vendors/${actions.VendorsAction.SET_OR_DELETE_VENDOR}`,
        docOperation,
      )
      loading.value = false
      if (isSuccess) {
        router.push({ name: 'vendors' })
      }
    }

    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const handleAddContactPerson = (contactPerson: IContactPerson) => {
      formState?.contactPersons?.push(contactPerson)
    }

    const onAliasInputBlur = () => {
      // TODO: Check for unique alias from store
      formState.alias = formState.alias?.replace(/\s/g, '').toLowerCase() ?? null
    }

    const fetchProfile = async (vid?: string) => {
      if (vid) {
        isProfileLoading.value = true
        const resp: VendorsDocument | null = await store.dispatch(
          `vendors/${actions.VendorsAction.FETCH_VENDOR_PROFILE}`,
          {
            id: vid,
          },
        )
        if (!resp) {
          message.warn('Customer does not exists.')
          router.push({ name: 'vendors' })
        } else {
          let { audit, ...doc } = resp
          Object.assign(formState, doc)
          fetchedAlias.value = doc.alias
          prevAudit.value = audit
        }
        isProfileLoading.value = false
      }
    }

    onBeforeMount(async () => {
      await fetchProfile(route.params.id as string)
    })

    onBeforeRouteUpdate(async (to) => {
      await fetchProfile(to.params.id as string)
    })

    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      loading,
      handleFinish,
      handleFinishFailed,
      isAddContactPersonModalVisible,
      handleAddContactPerson,
      contactPersonColumns,
      displayNameOptions,
      onAliasInputBlur,
      isProfileLoading,
      isEdit,
    }
  },
})
